.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.header {
	background-color: #ecebeb;
	display: flex;
	justify-content: space-around;
	padding: 1em;
	margin: 0 0 1em 0;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
}

.shadow {
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;

}

.logo-header {
	max-width: 20em;
}

.return-button {
	background-color: #ff8d22;
	transition-duration: 500ms;
	color: aliceblue;
	min-height: 3em;
	max-height: 3em;
	min-width: 8em;
	max-width: 10em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 0.1em;
	font-size: 18px;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	cursor: pointer;
	margin: 0 1.5em 0.8em;
}

.return-button:hover {
	background-color: #e84e1b;
	;
	transition-duration: 500ms;
	color: aliceblue;
	min-height: 3em;
	max-height: 3em;
	min-width: 8em;
	max-width: 10em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 0.1em;
	font-size: 18px;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	cursor: pointer;
	margin: 0 1.5em 0.8em;
}

.main-menu-button {
	background-color: #ff8d22;
	transition-duration: 500ms;
	color: aliceblue;
	min-height: 4em;
	max-height: 4em;
	min-width: 18em;
	max-width: 20em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 0.1em;
	font-size: 18px;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	cursor: pointer;
}

.finalizar-compra-button {
	background-color: #ff8d22;
	transition-duration: 500ms;
	min-height: 4em;
	max-height: 4em;
	color: aliceblue;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 0.1em;
	font-size: 18px;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	cursor: pointer;
	margin: 1em 0 0 0;


}

.finalizar-compra-button:hover {
	background-color: #e84e1b;
	transition-duration: 500ms;
	min-height: 4em;
	max-height: 4em;
	color: aliceblue;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 0.1em;
	font-size: 18px;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	cursor: pointer;
	margin: 1em 0 0 0
}

.main-menu-button:hover {
	background-color: #e84e1b;
	color: aliceblue;
	transition-duration: 500ms;
	min-height: 4em;
	max-height: 4em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	padding: 0.1em;
	font-size: 18px;
	font-weight: 600;
}

.add-button {
	margin-top: 5px;
	color: #ff8d22;
	padding: 0.1em;
	transition-duration: 500ms;
	cursor: pointer;

}

.add-button:hover {
	margin-top: 5px;
	color: #e84e1b;
	padding: 0.1em;
	transition-duration: 500ms;
	cursor: pointer;
}

.info-button {
	margin-top: 5px;
	color: #ff8d22;
	padding: 0 1em;
	transition-duration: 500ms;
	cursor: pointer;

}

.info-button:hover {
	margin-top: 5px;
	color: #e84e1b;
	padding: 0 1em;
	transition-duration: 500ms;
	cursor: pointer;
}

.Cancel-form {
	background-color: #ff8d22;
	color: white;
	cursor: pointer;
	padding: 0.5em 0.7em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;

}

.Cancel-form:hover {
	background-color: #e84e1b;
	color: white;
	cursor: pointer;
	padding: 0.5em 0.7em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;

}

.add-proveedor-button {
	background-color: #ff8d22;
	color: white;
	cursor: pointer;
	font-size: 20px;
	padding: 0.7em 1em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;

}

.diss-proveedor-button {
	background-color: #6e6e6eaf;
	color: rgb(177, 177, 177);
	cursor: pointer;
	font-size: 20px;
	padding: 0.7em 1em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;
	box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.add-proveedor-button:hover {
	background-color: #e84e1b;
	color: white;
	cursor: pointer;
	font-size: 20px;
	padding: 0.7em 1em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;

}

.flex-container {
	display: flex;
	justify-content: center;
}

.flex-rigth {
	display: flex;
	justify-content: right;

}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.options-containers {
	display: flex;
	justify-content: center;
	flex-direction: column;
	row-gap: 10px;
	column-gap: 20px;
	margin: 4em 0 0 0;

}

.image-bg {
	background-image: url(./media/bg-oranges.png);
	/* background-size: contain; */
	min-height: 100vh;
	width: 100%;
	resize: horizontal;
	text-transform: uppercase;
}

.card-view {
	background-color: rgb(248, 248, 248);
	max-width: 90%;
	min-width: 90%;
	min-height: 55vh;
	/* display: flex;
	justify-content: center; */
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
	/* flex-wrap: wrap; */
	position: relative;


}

.card-view-title {
	background-color: #3c3c3b;
	color: aliceblue;
	font-size: 40px;
	font-weight: 700;
	min-width: 100%;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	min-height: 8vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

body {
	text-transform: uppercase;
}

.card-view-body {
	margin: 1.5em 0.8em 0 0.8em;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 10px;
	column-gap: 5px;
}

.card-view-ejercicio {
	display: flex;
	align-items: center;
	background-color: #ecebeb;
	font-size: 25px;
	font-weight: 500;
	padding: 0.5em 0.5em;
	border-radius: 5px;
	min-width: 12em;
	max-width: 12em;
}

.card-view-element {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ecebeb;
	flex-flow: wrap;
	font-size: 25px;
	font-weight: 500;
	padding: 0.5em 0.5em;
	border-radius: 5px;
	min-width: 12em;
	max-width: 12em;
	min-height: 2em;
	/* max-height: 2em; */


}

.text-field {
	font-size: 20px;
	/* max-width: 8em; */
	min-width: 18em;
}

.text-field-empty {
	font-size: 20px;
	/* max-width: 8em; */
	min-width: 18em;
	background-color: rgba(255, 0, 0, 0.178);
}

.bottom-space {
	margin: 0 0.5em 1.5em 0.5em;
}

.dateInputContainer {}

.firmar-button {
	background-color: #ff8d22;
	color: white;
	cursor: pointer;
	padding: 0.5em 0.7em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;
	font-size: 25px;

}

.firmar-button:hover {
	background-color: #e84e1b;
	color: white;
	cursor: pointer;
	padding: 0.5em 0.7em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;
	font-size: 25px;

}

.emptey-alert {
	background-color: #646464;
	color: white;
	cursor: default;
	padding: 0.8em 1em;
	border-radius: 6px;
	margin: 0.5em;
	transition-duration: 500ms;
	user-select: none;
	font-size: 25px;
}

.inputDateInput {
	min-height: 2em;
	background-color: #ffffff00;
	color: #0f0f0fb0;
	/* max-width: 6em; */
	border-radius: 5px;
	font-size: 25px;
	border-color: #3c3c3b2f;
	font-family: 'Times New Roman', Times, serif;
}

.inputDateInput-empty {
	min-height: 2em;
	background-color: rgba(255, 0, 0, 0.178);
	color: #0f0f0fb0;
	/* max-width: 6em; */
	border-radius: 5px;
	font-size: 25px;
	border-color: #3c3c3b2f;
	font-family: 'Times New Roman', Times, serif;
}

.card-view-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ecebeb;
	flex-flow: wrap;
	font-size: 25px;
	font-weight: 500;
	padding: 0.5em 1em;
	border-radius: 5px;

}

.buttons-container {
	min-width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
}

.cp {
	min-width: 90%;
}

.proveedor-form {
	max-width: 100%;
}

/* listado compras */
.header-compras-list {
	background-color: #ecebeb;
	max-width: 90%;
	min-width: 90%;
	min-height: 10vh;
	border-top-right-radius: 30px;
	border-top-left-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 45px;
	font-weight: 700;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;

}

.browser {
	background-color: #ecebeb;
	max-width: 90%;
	min-width: 90%;
	min-height: 10vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 45px;
	font-weight: 700;
	margin-bottom: 0.4em;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 20px 16px 0px;
}

.browser-card {
	width: 90%;
	display: flex;
	align-items: center;
	color: #ff8d22;
}

.firma-img {
	max-width: 10em;
	max-height: 10vh;
	width: auto;
	/* Permite que la imagen mantenga su proporción */
	height: auto;
	/* Permite que la imagen mantenga su proporción */
}

.textfield {
	border-color: #342828;
}

.compras-list-container {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

}

.compras-list-card {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: start;
	/* max-width: 90%; */
	background-color: #eeeeee;
	margin: 0.3em;
	width: 100%;
	min-height: 5vh;
	font-size: 16px;
	font-weight: 500;
	/* border-radius: 20px; */
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;

}

.compras-list-element {
	padding: 0.5em 1em;
	margin-top: 0.1em;
	max-width: 10em;
	min-width: 4em;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid #d48bff;
	/* Light grey */
	border-top: 10px solid #ffffff00;
	/* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

.spinner {
	margin-top: 20vh;
}

.proveedor-element {
	display: flex;
	flex-wrap: wrap;
	font-weight: 500;
	font-size: 21px;
	justify-content: space-between;
	background-color: rgb(209, 209, 209);
	min-width: 30em;
	padding: 0.5em;
	border-radius: 6px;
	margin: 0.25em 0 0.25em;
}

.tip-text {
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}

.login-card {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: start;
	/* max-width: 22em; */
	background-color: #eeeeee;
	margin: 8em;
	min-height: 5vh;
	font-size: 20px;
	font-weight: 600;
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px 0px, rgba(0, 0, 0, 0.32) 0px 2px 16px 0px;
}

.A4 {
	max-width: 60em;
}

.login-element {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
}

.login-icon {
	min-width: 100%;
	max-height: 10vh;
	text-align: center;
	padding-top: 1em;
	color: #ff8d22;

}

.spacer {
	min-height: 20vh;
}

.flip {
	display: flex;
	align-items: center;
	transform: rotate(180deg);
}

.user-logged {
	max-width: 10em;
	min-width: 10em;
	min-height: 5vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	font-weight: 600;
	color: #3c3c3b;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vertical-menu {
	min-height: 40vh;
	display: flex;
	align-items: center;

}

.filtros {
	background-color: #eeeeee;
}

.fill {
	min-width: 100%;
}